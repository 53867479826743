import classNames from 'classnames';
import Logo from '@/components/Logo';

import styles from './header.module.css';

const HeaderSimple = () => {
  return (
    <div className={styles.root}>
      <header className={classNames(styles.header, styles.simple)}>
        <div className={styles.logo}>
          <Logo showText />
        </div>
      </header>
    </div>
  )
};

export default HeaderSimple;