import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './header.module.css';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

const Submenu = forwardRef(({items, openSubmenu}, ref) => {
  const { t, i18n } = useTranslation();

  const rootClassName = classNames(
    styles.submenuWrapper,
    openSubmenu && styles.show
  );

  return (
    items && (
      <div className={rootClassName} ref={ref}>
        <div>
          {items.map((elem) => (
            <a
              key={elem.id}
              href={`/${i18n.language}${elem.href}`}
              className={styles.item}
            >
              <ReactSVG src={elem.icon} wrapper="span" />
              {elem.name}
            </a>
          ))}
        </div>
      </div>
    )
  );
});

Submenu.displayName = 'Submenu';

export default Submenu;
