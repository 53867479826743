import { useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { ThemeContext } from '@forma/forma-ui-kit';
import { MainContext } from '@/context/MainContext';
import Link from '@/components/Link';

import styles from './footer.module.css';

const FooterColumn = ({ title, items, isPhone }) => {
  const [ isOpen, setOpen ] = useState(false);

  return (
    <div className={styles.column}>
      <div className={classNames(styles.title, isOpen && styles.open)} onClick={() => setOpen(!isOpen)}>
        {title}
        {isPhone && <ReactSVG src="/icons/arrow-down.svg" className={styles.arrow} />}
      </div>
      <div className={classNames(styles.items, isOpen && styles.open)}>
        {items.map(({ name, href, icon, title }, index) =>
          <div className={styles.item} key={index}>
            {href ? (
              <Link href={href} className={styles.link} title={title}>
                {icon && <ReactSVG className={styles.linkIcon} src={icon} wrapper="span" />} {name}
              </Link>
            ) : (
              <span className={classNames(styles.link, styles.cursorDefault)}>
                {icon && <ReactSVG className={styles.linkIcon} src={icon} wrapper="span" />} {name}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Footer = () => {
  const { t, i18n } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const { main } = useContext(MainContext);
  const isTablet = ['tablet', 'tabletS'].includes(viewport);
  const isPhone = viewport === 'phone';

  return (
    <footer className={classNames(styles.root, 'container')}>
      <div className={styles.column}>
        <Link href="https://t.me/platforma_by_forma" className={styles.writeButton}>
          <ReactSVG src="/icons/telegram.svg" className={styles.writeButtonIcon} wrapper="span" />
          {t('here_write_about_docs')}
        </Link>
        <div className={styles.description}>
          {t('footer_description')}
        </div>
        {!(isTablet || isPhone) && (
          <Link className={styles.created} href={`https://traektoria.pro/${i18n.language}`} title={t('links.traektoria')}>
            <img src="/images/creator.svg" className={styles.createdImage} alt={t('links.traektoria')} width="250px" height="13px" />
          </Link>
        )}
      </div>
      {main?.footer?.map((item, index) =>
        <FooterColumn {...item} isPhone={isPhone} key={index} />
      )}
      {(isTablet || isPhone) && (
        <div className={styles.column}>
          <Link className={styles.created} href={`https://traektoria.pro/${i18n.language}`} title={t('links.traektoria')}>
            <img src="/images/creator.svg" className={styles.createdImage} alt={t('links.traektoria')} width="250px" height="13px" />
          </Link>
        </div>
      )}
    </footer>
  );
};

export default Footer;
