import { useEffect, useState } from 'react';

const ClientWrap = ({ children }) => {
  const [ isLoaded, setIsLoaded ] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, [])
  if (!isLoaded) return null;
  return children;
};

export default ClientWrap;
