import { useTranslation } from 'next-i18next';
import classnames from 'classnames';
import Link from '@/components/Link';

import styles from './logo.module.css';

const LogoSimple = ({ showText, className }) => {
  const { t, i18n } = useTranslation();
  const alt = t('main_company_name');

  return (
    <Link
      href="/"
      className={classnames(styles.link, className)}
      title={t('links.product')}
    >
      <div className={styles.imageWrap}>
        <img
          className={styles.image}
          src="/images/small_logo.svg"
          alt={t('main_company_name') ?? ''}
          width="37px"
          height="37px"
        />
      </div>
      {showText &&
        <img className={styles.imageText} src={`/images/title_${i18n.language}.svg`} alt={alt} width="102px" height="19px" />
      }
    </Link>
  );
}

export default LogoSimple;