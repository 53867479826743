import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import Logo from '@/components/Logo';
import LangSwitch from '@/components/LangSwitch';
import SideMenu from '@/components/SideMenu';
import { MainContext } from '@/context/MainContext';

import styles from './header.module.css';
import Submenu from './Submenu';

const lkUrl = process.env.LK_URL;

const Header = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useRouter();
  const { viewport } = useContext(ThemeContext);
  const { main } = useContext(MainContext);
  const isTablet = ['tablet', 'tabletS'].includes(viewport);
  const isPhone = viewport === 'phone';

  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(false);

  const header = useRef(null);
  const submenu = useRef(null);
  const closeTimeout = useRef(null);

  const handleOnMouseEnter = () => {
    clearTimeout(closeTimeout.current);
    setOpenSubmenu(true);
    setIsMouseInside(true);
  };

  const handleOnMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setIsMouseInside(false);
    }, 500);
  };

  useEffect(() => {
    if (!submenu.current && !header.current) return;

    const submenuCur = submenu.current;
    const headerCur = header.current;

    const handleMouseMove = () => {
      if (!isMouseInside) setOpenSubmenu(false);
      if (isMouseInside) setOpenSubmenu(true);
    };

    const handleMouseLeave = () => {
      setOpenSubmenu(false);
    };

    submenuCur?.addEventListener('mouseleave', handleMouseLeave);
    headerCur?.addEventListener('mousemove', handleMouseMove);

    return () => {
      submenuCur?.removeEventListener('mouseleave', handleMouseLeave);
      headerCur?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseInside, openSubmenu]);

  return (
    <div className={classNames(styles.root, styles.sticky)}>
      <header className={styles.header} ref={header}>
        <div className={styles.logo}>
          {/* {(isPhone && isTablet) && <SideMenu />} */}
          <Logo showText={viewport !== 'phone'} />
        </div>
        {!isPhone && !isTablet && (
          <div className={styles.links}>
            <Button
              as={Link}
              href="/"
              className={classNames(
                styles.link,
                (pathname === '/' || pathname === '/[locale]') && styles.active
              )}
              viewStyle="text"
              // title={t('links.product')}
            >
              {t('product')}
            </Button>
            <Button
              as={Link}
              href="/tariffs"
              className={classNames(
                styles.link,
                (pathname === '/tariffs' || pathname === '/[locale]/tariffs') &&
                  styles.active
              )}
              viewStyle="text"
              // title={t('links.tariffs')}
            >
              {t('tariffs')}
            </Button>
            <Button
              as={Link}
              href="/request"
              className={classNames(styles.link)}
              viewStyle="text"
              // title={t('links.request')}
            >
              {t('demo')}
            </Button>
            <Button
              as={Link}
              href="/templates"
              className={classNames(
                styles.link,
                (pathname === '/templates' ||
                  pathname === '/[locale]/templates') &&
                  styles.active
              )}
              viewStyle="text"
              // title={t('links.templates')}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            >
              {t('templates')}
            </Button>
          </div>
        )}
        <div className={styles.buttons}>
          {isPhone ? (
            <>
              <Button
                as={Link}
                href={`${lkUrl}/register?lng=${i18n.language}`}
                className={styles.buttonMobile}
                viewStyle="text"
                icon={<ReactSVG src="/icons/sign_in.svg" wrapper="span" />}
                // title={t('registration')}
              ></Button>
            </>
          ) : (
            <>
              <Button
                as={Link}
                href={`${lkUrl}/login?lng=${i18n.language}`}
                className={styles.buttonLogin}
                viewStyle="secondary"
              >
                {t('login')}
              </Button>
              <Button
                as={Link}
                href={`${lkUrl}/register?lng=${i18n.language}`}
                className={styles.button}
                viewStyle="primary"
              >
                {t('registration')}
              </Button>
              {/* <Button
                as={Link}
                href="/request"
                className={styles.button}
                viewStyle="primary"
              >
                {t('request_demo')}
              </Button> */}
            </>
          )}
          {isTablet || isPhone ? (
            <SideMenu items={main?.menu} />
          ) : (
            <LangSwitch />
          )}
        </div>
      </header>
      {!isPhone && !isTablet && (
        <Submenu
          items={main?.submenu}
          openSubmenu={openSubmenu}
          ref={submenu}
        />
      )}
    </div>
  );
};

export default Header;
