import { useEffect, useState } from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useSearchParams } from 'next/navigation'
import { useCookie } from 'next-cookie';
import Head from 'next/head';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { ThemeContext, useViewport } from '@forma/forma-ui-kit';
import { MainContext } from '@/context/MainContext';
import { NotificationsProvider } from '@/context/NotificationsContext';
import { SharedProvider } from '@/context/SharedContext';
import useClient from '@/hooks/useClient';

import '@forma/forma-ui-kit/dist/style.css';

import Header from '@/components/Header';
import Footer from '@/components/Footer';
import CookieNotification from '@/components/CookieNotification';
import NotificationsWrapper from '@/components/NotificationsWrapper';
import { breakpoints } from '@/breakpoints';
import useClientI18next from '@/hooks/useClientI18next';

import 'rc-slider/assets/index.css';

import '@/styles/globals.css';
import '@/styles/slider.css';

import 'slick-slider/slick/slick.css';
import 'slick-slider/slick/slick-theme.css';

const apiUrl = process.env.API_URL;

const App = ({ Component, pageProps }) => {
  const { i18n } = useTranslation();
  const { initialState, headersParse, ...restPageProps } = pageProps;
  const cookie = useCookie();
  const searchParams = useSearchParams();
  const viewport = useViewport(breakpoints);
  const isClient = useClient();

  const theme = {
    viewport: isClient ? viewport : headersParse?.type,
    currencies: initialState?.main?.currencies ?? {},
    isMenuOpen: false,
    lang: i18n.resolvedLanguage ?? i18n.language ?? headersParse?.language,
    apiUrl: apiUrl ?? '',
    token: ''
  };

  if (i18n.language === 'ru') registerLocale('ru', ru);

  useEffect(() => {
    const date = new Date();
    const utm = cookie.get('utm') ?? {};
    for (const [key, value] of searchParams.entries()) {
      if (key !== 'locale') utm[key] = value;
    }
    cookie.set('utm', JSON.stringify(utm), {
      path: '/',
      expires: new Date(date.setDate(date.getDate() + 7)),
      domain: window.location.hostname
    });
    // eslint-disable-next-line
  }, [searchParams]);

  useClientI18next();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <ThemeContext.Provider value={theme}>
        <MainContext.Provider value={initialState}>
          <SharedProvider>
            <NotificationsProvider>
              <div id='page'>
                {Component.header ? Component.header() : <Header />}

                <NotificationsWrapper />
                <CookieNotification />

                <main className="main-container container content">
                  <Component {...restPageProps} />
                </main>

                {Component.footer ? Component.footer() : <Footer />}
              </div>
            </NotificationsProvider>
          </SharedProvider>
        </MainContext.Provider>
      </ThemeContext.Provider>
    </>
  );
};

export default appWithTranslation(App);
