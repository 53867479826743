import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import Link from '@/components/Link';

import styles from './logo.module.css';

const Logo = ({ showText, className }) => {
  const { t, i18n } = useTranslation();
  const [ isHover, setIsHover ] = useState(false);
  const alt = t('main_company_name');

  return (
    <Link
      href="/"
      className={classnames(styles.link, className, isHover && styles.hover)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      title={t('links.product')}
    >
      <div className={styles.imageWrap}>
        <img
          className={styles.image}
          src="/images/small_logo.svg"
          alt={t('main_company_name') ?? ''}
          width="37px"
          height="37px"
        />
        <ReactSVG
          className={styles.imageHover}
          src="/images/small_logo_animated.svg"
        >
          {t('main_company_name') ?? ''}
        </ReactSVG>
      </div>
      {showText && (
        <span className={styles.imageText}>{alt}</span>
      )}
      {/* <img className={styles.imageText} src={`/images/title_${i18n.language}.svg`} alt={alt} width="102px" height="19px" /> */}
    </Link>
  );
}

export default Logo;