import { useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Link from '@/components/Link';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import { LangSwitchDropdown } from '@/components/LangSwitch';
import Logo from '@/components/Logo';
import ClientWrap from '@/components/ClientWrap';
import styles from './side-menu.module.css';

const lkUrl = process.env.LK_URL;

const SideMenu = ({ items }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useRouter();
  const { viewport } = useContext(ThemeContext);
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <ReactSVG src="/icons/hamburger.svg" className={styles.menuButton} onClick={() => setIsOpen(true)} />
      <div className={classNames(styles.overlay, isOpen && styles.open)} onClick={() => setIsOpen(false)} />
      <div className={classNames(styles.menu, isOpen && styles.open)}>
        <div className={styles.head}>
          {viewport === 'phone' ? (
            <Logo />
          ) : (
            t('menu')
          )}
          <ReactSVG src="/icons/close.svg" className={styles.closeButton} onClick={() => setIsOpen(false)} />
        </div>
        <div className={styles.content}>
          <div className={styles.items}>
            {items?.map(({ id, items, title, href }) => (
              <div className={styles.item} key={id}>
                {href ? <Link className={styles.title} href={href} onClick={() => setIsOpen(false)}>{title}</Link> : <div className={styles.title}>{title}</div>}
                {items?.map(({ id, name, href, title }) => (
                  <div key={id}>
                    <Link
                      href={href}
                      className={classNames(styles.link, (pathname === '/' || pathname === '/[locale]') && styles.active)}
                      onClick={() => setIsOpen(false)}
                      title={title}
                    >
                      {name}
                    </Link>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            {/* <Button
              as={Link}
              className={styles.button}
              viewStyle="primary"
              href={`${lkUrl}/register?lng=${i18n.language}`}
              fullWidth
            >
              {t('registration')}
            </Button> */}
            <Button
              as={Link}
              className={styles.button}
              viewStyle="primary"
              href="/request"
              onClick={() => setIsOpen(false)}
              fullWidth
            >
              {t('request_demo')}
            </Button>
            <Button
              as={Link}
              className={styles.button}
              viewStyle="tertiary"
              href={`${lkUrl}/login?lng=${i18n.language}`}
              fullWidth
            >
              {t('login')}
            </Button>
            <ClientWrap>
              <LangSwitchDropdown onChange={() => setIsOpen(false)} />
            </ClientWrap>
            
            {/* <Button
              className={styles.button}
              viewStyle="secondary"
              href="/request"
              shadow={true}
              fullWidth={true}
              onClick={() => setIsOpen(false)}
            >
              {t('request_demo')}
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
