import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { LogoSimple } from '@/components/Logo';

import styles from './footer.module.css';

const FooterSimple = () => {
  const { t } = useTranslation();

  return (
    <footer className={classNames(styles.root, styles.simple, 'container')}>
      <div className={styles.createWithForma}>
        {t('create_documents_with_forma')} <LogoSimple className={styles.logo} />
      </div>
    </footer>
  );
};

export default FooterSimple;
